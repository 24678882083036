<h1 jhiTranslate="artemisApp.userSettings.accountInformation"></h1>
@if (currentUser) {
    <div class="list-group d-block">
        @if (currentUser.name) {
            <div class="list-group-item">
                <dt jhiTranslate="artemisApp.userSettings.accountInformationPage.fullName"></dt>
                <dd>{{ currentUser.name }}</dd>
            </div>
        }
        @if (currentUser.login) {
            <div class="list-group-item">
                <dt jhiTranslate="artemisApp.userSettings.accountInformationPage.login"></dt>
                <dd>{{ currentUser.login }}</dd>
            </div>
        }
        @if (currentUser.email) {
            <div class="list-group-item">
                <dt jhiTranslate="artemisApp.userSettings.accountInformationPage.email"></dt>
                <dd>{{ currentUser.email }}</dd>
            </div>
        }
        @if (currentUser.visibleRegistrationNumber) {
            <div class="list-group-item">
                <dt jhiTranslate="artemisApp.userSettings.accountInformationPage.registrationNumber"></dt>
                <dd>{{ currentUser.visibleRegistrationNumber }}</dd>
            </div>
        }
        @if (currentUser.createdDate) {
            <div class="list-group-item">
                <dt jhiTranslate="artemisApp.userSettings.accountInformationPage.joinedArtemis"></dt>
                <dd>{{ currentUser.createdDate | artemisDate }}</dd>
            </div>
        }
    </div>
}
